import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FinanceCarLoanEligibiltyHomeBanner from "./component";
import { setPreApprovedOrigin } from "../pre-approval-financing-flow/pre-approval-loan-wrapper/actions";
import { clearAllFilters } from "../filters/actions";
const mapStateToProps = ({
    preApprovalLoan: {
        preApproved
    },
    checkout: {
        stEligibleData
    }
}) => ({
    preApproved,
    stEligibleData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setPreApprovedOriginConnect: setPreApprovedOrigin,
    clearAllFiltersConnect: clearAllFilters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinanceCarLoanEligibiltyHomeBanner);
