import React from "react";
import styles from "./styles.css";
import LadyImage from "./images/lady.png";
import PercentIcon from "./images/percent.png";
import Button from "../../shared/button";
import { useHistory } from "react-router-dom";
import { FINANCE_FIRST_EXPERIMENT_AMPLITUDE_EVENTS } from "../../../tracking/amplitude-events";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import PropTypes from "prop-types";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
import TrackImpression from "../../shared/track-impression";
import GetPreApprovedCardRevamp from "../get-pre-approved-card-revamp";
import GetPreApprovedCardVariantB from "../get-pre-approved-car-b";
import Bolt from "./images/bolt.svg";
import OurRateMatchPromisePopup from "../our-rate-match-promise-popup/component";

const FinanceCarLoanEligibiltyHomeBanner = ({
    preApproved,
    setPreApprovedOriginConnect,
    showFinanceFirstExperimentVariant,
    isPersonal
}) => {
    const [isRateMatchPopupVisible, setIsRateMatchPopupVisible] = React.useState(false);
    const history = useHistory();
    const handlePreApproval = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.FF_VOLUME_EXPERIMENT, {
            eventAction: "Finance_card_clicked",
            eventLabel: "Get_pre_approval_Home_page"
        });
        setPreApprovedOriginConnect(CAR_LIST_EVENT_NAMES.HOME_PAGE);
        history.push("/financing/get-pre-approval");
    };

    const handleBestInterestClick = () => {
        setIsRateMatchPopupVisible(true);
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.INTEREST_RATE_MATCH, {
            eventAction: "Rate_match_promise_source",
            eventLabel: "Home_page"
        });
    };

    if (!preApproved) {
        return (
            <React.Fragment>
                <TrackImpression event={{gaName: AU_DESKTOP_EVENTS.FF_VOLUME_EXPERIMENT, data: {eventLabel: "Finance_Home_page", eventAction: "Card_viewed"}, amplitudeName: FINANCE_FIRST_EXPERIMENT_AMPLITUDE_EVENTS.CARD_VIEWED, properties: {"Card_viewed": "Finance_Home_page"}}}>
                    <div styleName={"styles.outer"}>
                        <div styleName={"styles.topSection"}>
                            <div className="media" styleName={"styles.mediaWrapper"}>
                                <div styleName={"styles.imageWrapper"}>
                                    <img src={LadyImage} />
                                </div>
                                <div className="media-body">
                                    <p styleName={"styles.heading"}>Car loan eligibility in <span>2 min</span></p>
                                </div>
                            </div>
                        </div>
                        <Button text="get pre-approval" onClick={handlePreApproval}/>
                        <p styleName={"styles.noImpact"}>No impact on credit score</p>
                        <div styleName={"styles.stBrandingStyle"}>
                            <div styleName={"styles.bestInterestWrap"}>
                                <img src={PercentIcon} />
                                <p styleName={"styles.bestInterest"} onClick={handleBestInterestClick}>{"Best interest rates >>"}</p>
                            </div>
                            <div styleName={"styles.fasterLoanText"}>
                                <img src={Bolt} alt="bolt" />
                                <p styleName={"styles.stText"}>12X faster loans</p>
                            </div>
                        </div>
                    </div>
                </TrackImpression>
                {isRateMatchPopupVisible && <OurRateMatchPromisePopup setIsRateMatchPopupVisible={setIsRateMatchPopupVisible} isRateMatchPopupVisible={isRateMatchPopupVisible} />}
            </React.Fragment>
        );
    } else {
        if (showFinanceFirstExperimentVariant && isPersonal) {
            return <GetPreApprovedCardVariantB />;
        }
        return <GetPreApprovedCardRevamp />;
    }
};

export default FinanceCarLoanEligibiltyHomeBanner;

FinanceCarLoanEligibiltyHomeBanner.propTypes = {
    preApproved: PropTypes.bool,
    setPreApprovedOriginConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    showFinanceFirstExperimentVariant: PropTypes.bool,
    isPersonal: PropTypes.bool,
    stEligibleData: PropTypes.object
};
