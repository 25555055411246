import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GetPreApprovedCardVariantB from "./component";
import { setPreApprovedOrigin } from "../pre-approval-financing-flow/pre-approval-loan-wrapper/actions";
import { clearAllFilters } from "../filters/actions";
import { updateFilterOptionData, updateSelectedSuggestions } from "../filters/actions";
import { reloadCarList } from "../car-listing/actions";
const mapStateToProps = ({
    preApprovalLoan: {
        preApproved,
        maxPermissibleRepaymentPerWeek,
        absolutePermissibleLoanAmount,
        firstName: preApprovalFirstName,
        bestInterestRateCalculatorDetails
    },
    user: {
        firstName
    },
    filters: {
        allFilters
    }
}) => ({
    preApproved,
    firstName,
    maxPermissibleRepaymentPerWeek,
    absolutePermissibleLoanAmount,
    preApprovalFirstName,
    bestInterestRateCalculatorDetails,
    allFilters
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setPreApprovedOriginConnect: setPreApprovedOrigin,
    clearAllFiltersConnect: clearAllFilters,
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateSelectedSuggestionsConnect: updateSelectedSuggestions,
    reloadCarListConnect: reloadCarList

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GetPreApprovedCardVariantB);
