import makePriceLabel from "./make-price-label";

export const getPreApprovedAmount = (absolutePermissibleLoanAmount = null, maxPermissibleRepaymentPerWeek) => {
    if (absolutePermissibleLoanAmount) {
        return `${(makePriceLabel(absolutePermissibleLoanAmount))}`;
    }

    return `${(makePriceLabel(maxPermissibleRepaymentPerWeek || 0))}/week`;
};

export const getffFooterText = (absolutePermissibleLoanAmount = null, maxPermissibleRepaymentPerWeek) => {
    if (absolutePermissibleLoanAmount) {
        return `Select a car priced below ${makePriceLabel(absolutePermissibleLoanAmount || 0)}`;
    }

    return `Select a car with repayment less than ${makePriceLabel(maxPermissibleRepaymentPerWeek || 0)}/week`;
};
